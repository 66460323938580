<template>
	<section class="data-table">
		<div class="filters">
			<div class="filter">
				<div class="input" v-if="mode === 'lab'">
					<label class="checkbox">
						<span class="checkbox__input">
							<input type="checkbox" name="checkbox" v-model="filterUnread" />
							<span class="checkbox__control">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									aria-hidden="true"
									focusable="false"
								>
									<path
										fill="none"
										stroke="currentColor"
										stroke-width="3"
										d="M1.73 12.91l6.37 6.37L22.79 4.59"
									/>
								</svg>
							</span>
						</span>
						<span class="radio__label">Ungelesen</span>
					</label>
				</div>
				<div class="input" v-if="mode === 'lab'">
					<label class="checkbox">
						<span class="checkbox__input">
							<input type="checkbox" name="checkbox" v-model="filterCallBack" />
							<span class="checkbox__control">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									aria-hidden="true"
									focusable="false"
								>
									<path
										fill="none"
										stroke="currentColor"
										stroke-width="3"
										d="M1.73 12.91l6.37 6.37L22.79 4.59"
									/>
								</svg>
							</span>
						</span>
						<span class="radio__label">Bitte um Rücksprache</span>
					</label>
				</div>
			</div>
			<div class="sort-search">
				<div class="input">
					<select id="sort" v-model="sortKey">
						<option value="">Sortieren nach:</option>
						<option value="date_created_desc">Datum (älteste zuerst)</option>
						<option value="date_created_asc">Datum (neuste zuertst)</option>
						<option value="name">Artz</option>
						<option value="lastname">Patientennamen</option>
					</select>
				</div>

				<div class="input space">
					<input
						type="text"
						placeholder="Suchen"
						v-model="searchTerm"
						class="search-field"
					/>
					<button class="button" @click="resetFilter">
						Filter zurücksetzen
					</button>
				</div>
				<div class="input" v-if="mode === 'doctor'">
					<button class="button new" @click="$router.push('/auftrag')">
						Neuer Auftrag
					</button>
				</div>
			</div>
		</div>
		<table class="table" :class="mode">
			<thead>
				<th>#</th>
				<th>{{ mode === 'doctor' ? 'Zahnlabor' : 'Zahnarzt' }}</th>
				<th>PatientIn</th>
				<th>Datum</th>
				<th>Uhrzeit</th>
				<th>Größe</th>
				<th>Optionen</th>
			</thead>
			<tbody>
				<tr
					v-for="(order, idx) in filteredRows"
					:key="order.id"
					:class="{
						selected: selectedIndex === idx,
						unread: order.date_read === null
					}"
				>
					<td>{{ order.id }}</td>
					<td v-if="$store.getters.getAppMode === 'lab'">
						{{ order.doctor.name }}
					</td>
					<td v-else>{{ order.lab.name }}</td>
					<td>
						{{ order.overview.firstname + ' ' + order.overview.lastname }}
					</td>
					<td>
						{{ order.date_created | momentDate }}
					</td>
					<td>
						{{ order.date_created | momentTime }}
					</td>
					<td>
						{{ order.filesize | toMB }}
					</td>
					<td>
						<a class="option" @click.prevent="showFiles(idx, order.UUID)">
							<unicon
								name="copy"
								:fill="'#006a9f'"
								icon-style="line"
								width="21"
								height="21"
							/>
						</a>
						<a class="option" @click.prevent="showData(idx, order.UUID)">
							<unicon
								name="file-alt"
								:fill="'#006a9f'"
								icon-style="line"
								width="21"
								height="21"
							/>
						</a>
						<a
							class="option"
							@click.prevent="openMessages(idx, order.UUID)"
							v-if="order.aes_key_doc !== ''"
						>
							<unicon
								name="comment-dots"
								:fill="'#006a9f'"
								icon-style="line"
								width="21"
								height="21"
							/>
						</a>
						<a
							class="option"
							@click.prevent="removeRecord(idx, order.UUID)"
							v-if="!(mode === 'doctor' && order.date_read !== null)"
						>
							<unicon
								name="trash-alt"
								:fill="'#006a9f'"
								icon-style="line"
								width="21"
								height="21"
							/>
						</a>
					</td>
				</tr>
			</tbody>
		</table>
	</section>
</template>

<script>
import formatMixin from '@/mixins/format.mixin';
import moment from 'moment';
import Swal from 'sweetalert2';
export default {
	name: 'Datatable',
	props: {
		entries: {
			type: Array,
			default: function () {
				return [];
			}
		}
	},
	mixins: [formatMixin],
	data() {
		return {
			orders: [],
			selectedIndex: -1,
			filterUnread: false,
			filterCallBack: false,
			sortKey: '',
			searchTerm: '',
			mode: this.$store.getters.getAppMode
		};
	},
	watch: {
		entries: function (val) {
			this.orders = val;
		}
	},
	computed: {
		filteredRows() {
			let data = [...this.orders] || [...this.entries];
			if (this.filterUnread) {
				data = this.filterByUnread(data);
			}
			if (this.filterCallBack) {
				data = this.filterByCallBack(data);
			}
			if (this.sortKey !== '') {
				data = this.sortBy(data, this.sortKey);
			}
			if (this.searchTerm !== '') {
				data = this.search(data, this.searchTerm);
			}
			return data;
		}
	},
	methods: {
		filterByUnread(data) {
			return data.filter((e) => e.date_read === null);
		},
		filterByCallBack(data) {
			return data.filter((e) => e.overview.callback === true);
		},
		sortBy(data, key) {
			switch (key) {
				case 'date_created_asc':
					return data.sort(this.sortDate('date_created', -1));
				case 'date_created_desc':
					return data.sort(this.sortDate('date_created', 1));
				case 'name':
					return data.sort(this.compareString(key));
				case 'lastname':
					return data.sort(this.compareString('overview', key));
				default:
					return [...this.entries];
			}
		},
		search(data, term) {
			return data.filter((e) => {
				const name = e.name.toLowerCase();
				const date = moment(e.date_created).format('DD.MM.YYYY HH:mm');
				const firstname = e.overview.firstname.toLowerCase();
				const lastname = e.overview.lastname.toLowerCase();
				const searchTerm = term.toLowerCase();
				return (
					name.includes(searchTerm) ||
					firstname.includes(searchTerm) ||
					lastname.includes(searchTerm) ||
					date.includes(searchTerm)
				);
			});
		},
		resetFilter() {
			this.filterUnread = false;
			(this.filterCallBack = false), (this.sortKey = '');
			this.searchTerm = '';
		},
		async removeRecord(idx, id) {
			if (this.mode === 'doctor' && this.filteredRows[idx].date_read !== null) {
				return;
			}
			const result = await Swal.fire({
				title: 'Akte wird gelöscht',
				html: 'Soll die Akte wirklich gelöscht werden?',
				icon: 'warning',
				showCloseButton: false,
				showCancelButton: true,
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				confirmButtonText: 'Ja!',
				cancelButtonText: 'Abbrechen'
			});
			if (result.isConfirmed) {
				this.orders = this.orders.filter((o) => o.id !== id);
				await this.$api.post(
					'/user/delete_record',
					{
						recordUUID: id
					},
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
				this.$emit('reload');
			}
		},

		showData(idx, id) {
			if (this.$store.getters.getAppMode === 'lab') {
				this.$api.post(
					'/lab/mark_as_read',
					{
						recordID: id
					},
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
			}
			this.$emit('showData', id);
		},

		showFiles(idx, id) {
			if (this.$store.getters.getAppMode === 'lab') {
				this.$api.post(
					'/lab/mark_as_read',
					{
						recordID: id
					},
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
			}
			this.$emit('showFiles', id);
		},

		openMessages(idx, id) {
			this.$emit('openMessages', id);
		},

		compareString(key, key2 = null) {
			return function (a, b) {
				let x;
				let y;
				if (key2 === null) {
					x = a[key].toLowerCase();
					y = b[key].toLowerCase();
				} else {
					x = a[key][key2].toLowerCase();
					y = b[key][key2].toLowerCase();
				}
				if (x < y) {
					return -1;
				}
				if (x > y) {
					return 1;
				}
				return 0;
			};
		},
		sortDate(key, order) {
			return function (a, b) {
				const x = moment(a[key]);
				const y = moment(b[key]);
				if (x.isBefore(y)) {
					return -1 * order;
				}
				if (x.isAfter(y)) {
					return 1 * order;
				}
				return 0;
			};
		},
		sortNumber(key) {
			return function (a, b) {
				const x = a[key];
				const y = b[key];
				if (x < y) {
					return -1;
				}
				if (x > y) {
					return 1;
				}
				return 0;
			};
		}
	}
};
</script>

<style lang="scss"></style>
